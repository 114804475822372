import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { ChartService } from "src/app/shared/shared-components/small-line-chart/chart.service";
import { Colors } from "src/app/shared/services/colors.service";
import { RequestServices } from "src/app/shared/services/request.services";
import { GlobalService } from "src/app/shared/services/global.service";
import { ConsumerModalComponent } from "src/app/shared/shared-components/consumer-modal/consumer-modal-component/consumer-modal.component";
import { SmallLineChartComponent } from "src/app/shared/shared-components/small-line-chart/small-line-chart/small-line-chart.component";
import { GaugeChartComponent, GaugeChartModule } from "angular-gauge-chart";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { StockChart } from "angular-highcharts";

import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ResellerService } from "src/app/shared/services/reseller.service";

import * as Highcharts from "highcharts";
import { webSocket } from "rxjs/webSocket";
import { heLocale } from "ngx-bootstrap/chronos";

import { NgxSpinnerService } from "ngx-spinner";
import { BarChartComponent } from "src/app/components/bootstrap/charts/bar-chart/bar-chart.component";
import { DatePipe } from "@angular/common";
import { LineChartComponent } from "src/app/components/bootstrap/charts/line-chart/line-chart.component";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import tippy from "tippy.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

declare var google;
declare var $;
declare var markerClusterer: any;
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit, AfterViewInit {
  stock: StockChart;
  stock1: StockChart;
  @ViewChild("gmap") gmapElement: any;
  @ViewChild("contentModal") private notifyAlertModal: ConsumerModalComponent;
  @ViewChild('content') public templateref: TemplateRef<any>;
  @ViewChild('qrcontent',{ static: false }) public content!: ElementRef<any>;

  @ViewChild("eTrappsAlarm") eTrappsAlarm: SmallLineChartComponent;
  @ViewChild("barchart") Pbarchart: BarChartComponent;
  @ViewChild("Duallinechart") duallinechart: LineChartComponent;
  @ViewChild("cartridges") cartridges: SmallLineChartComponent;
  @ViewChild("customerService") customerService: SmallLineChartComponent;
  @ViewChild("orderChart") orderChart: SmallLineChartComponent;
  @ViewChild("RewardsPoints") RewardsPoints: SmallLineChartComponent;
  @ViewChild("AlarmGauge") alarmGauge: GaugeChartModule;
  @ViewChild("addReseller") addResellerModal: TemplateRef<any>;

  @ViewChild("instructions") instructionsModal: TemplateRef<any>;
  resellerModalRef: BsModalRef;
  subscriptionmodalRef: BsModalRef;
  instructionsModalRef: BsModalRef;
  addResellerForm: FormGroup;

  map: any;
  mapLat: any;
  mapLong: any;
  userMarker: any = undefined;
  chartDataConfig: ChartService;
  Statistics: any = {
    TotalFilesAssigned: "2000",
    Placed: "1500",
    Budget: "$450,000 / 75%",
    YTDFee: "$24000",
    MTDFee: "$3400",
    Budget1: "$4000 / 85%",
    Today: "$50",
    Budget2: "$100 / 50%",
  };
  mapbarchartdata: number[] = []
  mapDualLine1: any;
  mapDualLine2: any;

  screenwidth: any = 0;
  screenheight: any = 0;
  changescreenwidth: any = 0;
  changescreenheight: any = 0;
  stylecss: any = "col-lg-2 col-xmd-4 col-md-4";
  barchart: any = "chart-container";

  airfilters: any = []

  dashboardVar = {
    mapdata: [],
    markers: [],
    alertLvlObj: {
      isHigh: true,
      isMedium: true,
      isLow: true,
    },
    marginVar: false,
    eTrappAlertsArr: [],
    cartridgeAlertsArr: [],
    previousEtrappDate: undefined,
    previousCartDate: undefined,
    previousChartsdataDate: undefined,
    previousChartsdataDateOnInit: undefined,
    previousEtrappDateOnInit: undefined,
    previousCartDateOnInit: undefined,
    eTrappSelectedVlaue: undefined,
    chartsSelectedValue: undefined,
    cartSelectedVlaue: undefined,
    loadingEtrappAlert: "",
    loadingCartAlert: "",
    modalDetailsComplete: undefined,
    daySinceNewCustomer: 0,
    totalCustomers: 0,
    alarmPercentage: 0,
    cartHealth: 0,
    etrappHealth: 0,
    resellers: [],
    resellerId: undefined,
  };
  rating: any;
  amplitudeoptions: any
  customerData: any = [];
  latitude = [];
  longitude = [];
  avglat = 30.345257;
  avglng = -89.984346;
  arrowHead = "fa fa-sort";

  notelist: Array<Note> = [];
  isFullScreen: any = false;
  showeTrappFullScreen: any = false;
  itemOrder = { label: "All", value: "all" };
  itemOptionsOrders = [
    { label: "Days", value: "0" },
    // { label: "8 hours", value: "8hours" },
    { label: "1 day", value: "1day" },
    { label: "7 days", value: "7days" },
    { label: "30 days", value: "30days" },

  ];
  smallChartData: any;
  schartHEtrapp: any;
  schartLEtrapp: any;
  schartAEtrapp: any;

  smallChartData1: any;
  schartLCart: any;
  schartHCart: any;
  schartACart: any;

  smallChartData2;
  schartLCustomer: any;
  schartHCustomer: any;
  schartACustomer: any;

  smallChartData3;
  schartLOrder: any;
  schartHOrder: any;
  schartAOrder: any;
  chartV = false;

  smallChartData4: any;
  schartLReward: any;
  schartHReward: any;
  schartAReward: any;

  etrappAlarmsChart: any;
  cartridgesChart: any;
  customerServiceChart: any;
  ordersChart: any;
  rewardsChart: any;

  AlarmGaugeChart: any = undefined;
  CartridgeGaugeChart: any = undefined;
  eTrappGaugeChart: any = undefined;
  totalfee: any[] = [];

  pubSubChannel: any = undefined;
  paymentForm: FormGroup;

  constructor(
    private chartService: ChartService,
    private services: RequestServices,
    public global: GlobalService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private resellerService: ResellerService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.chartDataConfig = this.chartService;
    this.etrappAlarmsChart = [];
    this.cartridgesChart = [];
    this.mapbarchartdata = [];
    this.duallabels = [];
    this.customerServiceChart = [];
    this.ordersChart = [];
    this.rewardsChart = [];
  }


  onSuccessFromParent() {
    this.loadEtrappAlerts();
  }


  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg",
  };

  ngAfterViewInit() {
    this.LoadMap();


    this.Rhlongtermchart()
    tippy('#Highcontainer', {
      content: 'This is a high alert level',
      placement: 'top',
      theme: 'light',
      animation: 'shift-away',
      delay: [100, 200],  // [show delay, hide delay]
    });
    tippy('#medium', {
      content: 'This is a medium alert level',
      placement: 'top',
      theme: 'light',
      animation: 'shift-away',
      delay: [100, 200],  // [show delay, hide delay]
    });
    tippy('#low', {
      content: 'This is a low alert level',
      placement: 'top',
      theme: 'light',
      animation: 'shift-away',
      delay: [100, 200],  // [show delay, hide delay]
    });




  }
  barChartOptions1: any;
  barChartdata: any;
  amplitudedata: any;
  duallinechartoptions: any;
  duallinechartdata: any;
  duallabels: string[] = [];

  newduallinechart() {
    this.duallinechartdata = {
      labels: this.duallabels,
      datasets: [
        {
          label: "RH",
          data: this.mapDualLine1,
          borderColor: "#457DBB",
          backgroundColor: "transparent",
          fill: false,
          pointStyle: null,
          pointRadius: 0,
          pointBackgroundColor: "#457DBB",
          borderWidth: 1,
        },
        {
          label: "Temp",
          data: this.mapDualLine2,
          borderColor: "#C04F4A",
          backgroundColor: "transparent",
          fill: false,
          pointStyle: null,
          pointRadius: 0,
          pointBackgroundColor: "#C04F4A",
          borderWidth: 1,
        },
      ],
    };

    this.duallinechartoptions = {
      responsive: true,
      maintainAspectRatio: false,
      background: null,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              // labelString: "Temperature (°C)",
            },
            ticks: {
              beginAtZero: false,
              autoSkip: true,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              tooltipFormat: 'MMM DD, YYYY HH:mm:ss',
              displayFormats: {
                hour: 'MMM DD,HH:mm',
              },
              scaleLabel: {
                display: true,
                labelString: "Time of the day",
              },
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 65,
                minRotation: 65
              },
              grid: {
                display: false,
                drawBorder: false,
              },


            },
          }
        ],
      },
      legend: {
        display: true,
        position: "top",
      },
      tooltips: {
        enabled: true,
      },
    };
  }

  RHlongtermchartdata: any;
  RHlingtermchartoptions: any

  Rhlongtermchart() {

    Highcharts.chart('rh-chart', {

      chart: {
        type: 'area',
        zooming: {
          type: 'x'
        },
        panning: true,
        panKey: 'shift',

      },
      title: {
        text: 'RH long term chart',
      },
      credits: {
        enabled: false
      },

      annotations: [],

      xAxis: {

        title: {
          text: 'Time of the day'
        },
        accessibility: {
          rangeDescription: 'Time from 10:30 AM to 06:30 PM'
        }
      },
      yAxis: {
        startOnTick: true,
        endOnTick: false,

        title: {
          text: 'Temperature (°F)'
        },
        labels: {
          format: '{value} °F'
        },
        plotLines: [],
        accessibility: {
          description: 'Temperature',
          rangeDescription: 'Range: 150 to 250 °F'
        }
      },

      tooltip: {
        headerFormat: 'temperature: {point.x:.1f} km<br>',
        pointFormat: '{point.y} m a. s. l.',
        shared: true
      },

      legend: {
        enabled: false
      },

      series: [{
        data: '',
        lineColor: Highcharts.getOptions().colors[1],
        color: 'white',
        fillOpacity: 0.5,
        name: 'Elevation',
        marker: {
          enabled: false
        },
        threshold: null
      },
      ],


    } as any);

  }
  onSearch(e) {
    this.customerData = [];
    var i = e.term;
    if (i.length >= 2) {
      if (this.customerData !== undefined) {
        if (this.customerData.length == 0) {
          this.services.getRequest(`Consumer/SearchConsumers?SearchValue=${e.term}&ProId=${this.global.getProObj().id}`).subscribe((response) => {
            if (response.statusCode === '200') {
              response.data.map((item) => {
                item.fullName = item.firstname + ' ' + item.lastname
              })
              this.customerData = response.data;
            }
            else if (response.statusCode === '404') {
              alert("No Data Found");
            }
          }, (error) => {
            console.log(error);
          });
        }
        else {
          this.customerData = this.customerData.filter(a => a.clientname.toLocaleLowerCase().includes(e.term));
        }
      }
      else {
        this.services.getRequest(`Consumer/SearchConsumers?SearchValue=${e.term}&ProId=${this.global.getProObj().id}`).subscribe((response) => {
          if (response.statusCode === '200') {
            this.customerData = response.data;
          }
        }, (error) => {
          console.log(error);
        })
      }
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.customerData = [];
    }
  }

  clearCustomerData() {
    this.customerData = [];
    this.etrapps = [];
    this.airfilters = []
    this.selectedmacid = null;
    this.dashboardVar.chartsSelectedValue = this.itemOptionsOrders[0];
    this.stock.destroy()
    this.stock1.destroy()
    this.stock = null
    this.stock1 = null
  }
  clearmacid() {
    this.selectedmacid = null;
    this.dashboardVar.chartsSelectedValue = this.itemOptionsOrders[0];
    this.stock.destroy()
    this.stock1.destroy()
    this.stock = null
    this.stock1 = null

  }
  customerselected: boolean = true
  etrapps: any = []
  selectedmacid: any
  macselected: boolean = true
  etrappqr: any =null

  selectMacID(event: any) {
    debugger
    this.etrappqr= event
    this.selectedmacid = event.macId
    this.macselected = false;
  }

  selectCustomer(event: any) {
    if (event) {
      this.global._show_loader = true;

      this.services.getRequest(`EnterpriseEtrappsBatch/GetEtrappsBatchById?ConsumerId=${event.id}`)
        .subscribe((response) => {

          if (response.statusCode === 201) {
            this.customerselected = false;
            this.etrapps = response.data
            this.global._show_loader = false;
          }
          else {
            this.global._show_loader = false;
            this.global.showNotification('Error', '', 'No Record exist');
          }
        }, (error) => {
          this.global.showNotification('Error', '', 'Something Went Wrong');
          this.global._show_loader = false;
        });
      this.getgraphsdata(event.id, 0)
    }
  }

  getgraphsdata(customerid, macid) {
    this.services.getRequest(`Dashboard/GetAirFilterAnalytics?CustomerID=${customerid}&EtrappUnitID=${macid}`)
      .subscribe((response) => {

        if (response.statusCode === 200) {
          this.airfilters = response.data
        }
        else {
          this.global._show_loader = false;
          this.global.showNotification('Error', '', 'No Record exist');
        }
      }, (error) => {
        this.global.showNotification('Error', '', 'Something Went Wrong');
        this.global._show_loader = false;
      });

  }
  createChart(series, acrunning) {

    const colors = ['#ff7270', '#7CB5EC', '#90ED7D'];
    series = series.map((s, index) => {
      return {
        ...s,
        color: colors[index % colors.length] // Assign color from the colors array
      };
    });

    const colorac = ['#7CB5EC', '#1976D2'];
    acrunning = acrunning.map((s, index) => {
      return {
        ...s,
        color: colorac[index % colorac.length]
      };
    });


    this.stock = new StockChart({

      rangeSelector: {
        buttons: [{
          type: 'hour',
          count: 1,
          text: '1h'
        }, {
          type: 'day',
          text: '1d'
        }, {
          type: 'all',
          text: 'All'
        }],
        selected: 4
      },


      yAxis: {
        labels: {
          format: '{#if (gt value 0)}{/if}{value}'
        },
        plotLines: [{
          value: 0,
          width: 2,
          color: 'silver'
        }]
      },

      plotOptions: {
        series: {

          showInNavigator: true,
          dataGrouping: {
            enabled: false
          }
        }
      },

      tooltip: {
        pointFormat: '<span style="color:{series.color}">' +
          '{series.name}</span>: <b>{point.y}</b> ' +
          '{point.change}<br/>',
        valueDecimals: 2,
        split: true
      },

      series: series,
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        borderWidth: 1
      }
    });

    this.stock1 = new StockChart({



      rangeSelector: {
        buttons: [{
          type: 'hour',
          count: 1,
          text: '1h'
        }, {
          type: 'day',
          count: 2,
          text: '1d'
        }, {
          type: 'all',
          count: 5,
          text: 'All'
        }],
        selected: 0
      },


      yAxis: {
        labels: {
          format: '{#if (gt value 0)}{/if}{value}'
        },

        plotLines: [{
          value: 0,
          width: 2,
          color: 'silver'
        }]
      },

      plotOptions: {
        series: {

          showInNavigator: true,
          dataGrouping: {
            enabled: false
          }
        }
      },

      tooltip: {
        pointFormat: '<span style="color:{series.color}">' +
          '{series.name}</span>: <b>{point.y}</b> ' +
          '{point.change}<br/>',
        valueDecimals: 2,
        split: true
      },

      series: acrunning,

      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        borderWidth: 1
      }

    });
    this.global.HideLoader("#stockchart");
    this.global.HideLoader("#acrunning");
    this.global.HideLoader("#airfilters");

  }



  getbarchartdata() {
    this.services
      .getRequest(`Dashboard/GetCartridgeAnalytics?proId=${this.global.getProObj().id}&Days=45&SlotSize=10`)
      .subscribe(
        (response) => {
          if (response.statusCode === 200) {

            const data = response.data;
            const counts = data.map(item =>
              this.mapbarchartdata.push(item.count)
            );
            console.log(this.barChartdata);
            this.Pbarchart.chart.update();
          } else if (response.Error) {
          }
        },
        (error) => { }
      );
  }

  

  getmodelnumber(){
    this.global._show_loader = true;
    
    this.services.getRequest(`/EtrappUnitModel/GetModelNumber?modelid=${this.etrappqr.modelId}`)
        .subscribe((response) => {
          if (response.statusCode === 200) {
            
            this.global._show_loader = false;
            const modelnumber=  response.data.modelNumber;
            this.generateqr(modelnumber)
          }
          else {
            this.global._show_loader = false;
            this.global.showNotification('Error', '', 'Model number not exist');
          }
        }, (error) => {
          this.global.showNotification('Error', '', 'Something Went Wrong');
          this.global._show_loader = false;
        });
    }


    generateqr(modelnumber: any){
      this.resellerModalRef = this.modalService.show(this.templateref, this.config);
      this.etrappqr.euId==null? this.etrappqr.euId= '0000-0000-0000-0000': this.etrappqr.euId
      const raw= modelnumber+'-'+ this.etrappqr.serialNumber + '-' +this.etrappqr.macId+'-'+this.etrappqr.euId+ '-'+ this.etrappqr.batch
      this.qrcodedata= raw

      console.log(this.qrcodedata)

    }
    downloadPDF(devicename: any): void {
      this.global._show_loader = true;
      const element = document.getElementById('modal-content');
      setTimeout(() => {
        debugger
        if (element) {
          html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'px',
              format: 'a4',
            });
  
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`QR_${devicename}.pdf`);
            this.global._show_loader = false;
          });
        } else {
          console.error('Content not found!');
        }
      }, 300); 
    }



  qrcodedata: any=null
  

  responsetime: any = []
  getaverageticketresponse() {
    this.services
      .getRequest(`Dashboard/GetSupportTicketAnalytics?proId=${this.global.getProObj().id}`)
      .subscribe(
        (response) => {
          if (response.statusCode === 200) {

            const data = response.data.avrResponseTime;

            response.data.avrageRating ? this.rating = ((response.data.avrageRating / 100) * 5).toFixed(2) : 0;
            // const checkdata= Math.abs(Math.round(data));

            // if (checkdata<=0 || checkdata<=6){

            //     const value=10
            //     this.responsetime.push(value)
            // }

            let checkdata = Math.abs(Math.round(data));

            if (checkdata < 0) {
              checkdata = 0;
            }
            else if (checkdata > 100) {
              checkdata = 100;
            }

            this.responsetime.push(checkdata)
            this.SpeedometerGaugechart();
          } else if (response.Error) {
            this.responsetime.push(0)

          }
        },
        (error) => { }
      );
  }



  performancebarchart() {

    this.barChartOptions1 = {
      legend: {
        position: "bottom",
        labels: {
          padding: 10,
          usePointStyle: true,
          fontSize: 0,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              lineWidth: 1,
              color: "rgba(0,0,0,0.1)",
              drawBorder: false,
            },
            ticks: {
              beginAtZero: false,
              stepSize: 4, // Adjust the step size as needed
              padding: 10, // Add some padding to the y-axis labels
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,

            },
            ticks: {
              autoSkip: false, // Ensures all labels are shown
              padding: 5, // Adjust padding if necessary
            },
          },
        ],
      },
      // tooltips: this.chartTooltip
    };
    this.barChartdata = {
      labels: ["5 days", "15 days", "25 days", "35 days", "45 days"],
      datasets: [

        {
          borderColor: Colors.getColors().themeColor1,
          backgroundColor: Colors.getColors().themeColor1,
          data: this.mapbarchartdata,
          borderWidth: 2,
        },
      ],
    };
    this.amplitudedata = {
      labels: ["0", "1", "2", "3", "4"],
      datasets: [
        {
          borderColor: Colors.getColors().themeColor1,
          backgroundColor: Colors.getColors().themeColor1,
          data: [0, 1, 0, 1, 0],
          borderWidth: 2,
        },
      ],
    };
    this.amplitudeoptions = {
      legend: {
        position: "bottom",
        labels: {
          padding: 10,
          usePointStyle: true,
          fontSize: 0,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              lineWidth: 1,
              color: "rgba(0,0,0,0.1)",
              drawBorder: false,
            },
            ticks: {
              beginAtZero: false,
              stepSize: 4, // Adjust the step size as needed
              padding: 10, // Add some padding to the y-axis labels
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,

            },
            ticks: {
              autoSkip: false, // Ensures all labels are shown
              padding: 5, // Adjust padding if necessary
            },
          },
        ],
      },
      // tooltips: this.chartTooltip
    };

    // this.notifyAlertAck.chart.update();
  }

  private createChartGauge(): void {
    const chart = Highcharts.chart("chart-gauge", {
      chart: {
        type: "solidgauge",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "80%"],
        size: "100%",
        background: {
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        stops: [
          [0.1, "#55BF3B"], // green
          [0.5, "#DDDF0D"], // yellow
          [0.9, "#DF5353"], // red
        ],
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -25,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: null,
          data: [this.dashboardVar.alarmPercentage],
        },
      ],
    } as any);
  }

  private SpeedometerGaugechart() {
    const chart = Highcharts.chart("Chart-gauge-speedo-meter", {
      chart: {
        type: "gauge",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "80%"],
        size: "110%",
        background: [],
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 0,
        tickPosition: "outside",
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
        tickLength: 0,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          distance: -20,
          style: {
            fontSize: "10px",
          },
          useHTML: true,
          formatter: function () {
            if (this.value === 0) {
              return '<div style="margin-top:25px; width: 105%"><span style="color:#55BF3B;">Gold Standard</span></div>';
            }
            if (this.value === 100) {
              return '<div style="margin-top: 20px;"><span style="color:#DF5353;">V Poor</span></div>';
            }
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 20,
            color: "#55BF3B",
            thickness: 40,
          },
          {
            from: 20,
            to: 40,
            color: "#90EE90", // green
            thickness: 40,
          },
          {
            from: 40,
            to: 60,
            color: "#DDDF0D", // yellow
            thickness: 40,
          },
          {
            from: 60,
            to: 80,
            color: "#FFA500", // yellow
            thickness: 40,
          },
          {
            from: 80,
            to: 100,
            color: "#DF5353", // red
            thickness: 40,
          },
        ],
      },

      series: [
        {
          name: "Speed",
          data: this.responsetime,
          tooltip: {
            enabled: false,
          },
          dataLabels: {
            format: "{y} hours",
            borderWidth: 0,
            color: "#333333",
            style: {
              fontSize: "14px",
            },
          },
          dial: {
            radius: "60%",
            backgroundColor: "gray",
            baseWidth: 15,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            backgroundColor: "gray",
            radius: 6,
          },
        },
      ],
    } as any);
  }

  private createChartGauge2(): void {
    const chart = Highcharts.chart("chart-gauge2", {
      chart: {
        type: "solidgauge",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "80%"],
        size: "100%",
        background: {
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        stops: [
          [0.1, "#DF5353"], // green
          [0.5, "#DDDF0D"], // yellow
          [0.9, "#55BF3B"], // red
        ],
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -25,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: null,
          data: [this.dashboardVar.cartHealth],
        },
      ],
    } as any);
  }
  private createChartGauge3(): void {
    const chart = Highcharts.chart("chart-gauge3", {
      chart: {
        type: "solidgauge",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "80%"],
        size: "100%",
        background: {
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        stops: [
          [0.1, "#DF5353"], // green
          [0.5, "#DDDF0D"], // yellow
          [0.9, "#55BF3B"], // red
        ],
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -25,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: null,
          data: [this.dashboardVar.etrappHealth],
        },
      ],
    } as any);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.changescreenwidth = window.innerWidth;
    this.changescreenheight = window.innerHeight;
    var ranger = this.screenwidth - 50;
    if (this.changescreenwidth >= ranger) {
      document
        .querySelector(".remover")
        .classList.add("justify-content-between");
      this.stylecss = "col-2";
      if (this.changescreenwidth > 700 && this.changescreenwidth < 1630) {
        document
          .querySelector(".remover")
          .classList.remove("justify-content-between");
        this.stylecss = "col-3";
        this.stylecss = "col-3";
      }
    } else if (this.changescreenwidth > this.screenwidth) {
      this.screenwidth = this.changescreenwidth;
      this.screenheight = this.changescreenheight;
      this.stylecss = "col-2";
      if (this.changescreenwidth > 700 && this.changescreenwidth < 1630) {
        this.stylecss = "col-4";
        this.stylecss = "col-4";
      }
    } else if (this.changescreenwidth < 700) {
      this.stylecss = "col-6";
    } else if (this.changescreenwidth > 700 && this.changescreenwidth < 1630) {
      this.stylecss = "col-3";
      this.stylecss = "col-3";
    }
    if (window.innerWidth < 1551 && window.innerWidth > 1215) {
      this.dashboardVar.marginVar = true;
    } else {
      this.dashboardVar.marginVar = false;
    }
  }

  @HostListener("fullscreenchange", ["$event"])
  @HostListener("webkitfullscreenchange", ["$event"])
  @HostListener("mozfullscreenchange", ["$event"])
  @HostListener("MSFullscreenChange", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.showeTrappFullScreen = !this.showeTrappFullScreen;
    if (!this.showeTrappFullScreen) {
      this.isFullScreen = false;
      document.exitFullscreen();
    }
  }
  EntityType: any;
  ngOnInit() {
    this.EntityType = JSON.parse(localStorage.getItem("EntityType"));
    // this.getSmallChartData("widgets");
    this.getbarchartdata();
    this.getaverageticketresponse()
    this.dashboardVar.eTrappSelectedVlaue = this.itemOptionsOrders[2];
    this.dashboardVar.cartSelectedVlaue = this.itemOptionsOrders[2];
    this.dashboardVar.chartsSelectedValue = this.itemOptionsOrders[0];
    this.loadMapdata();
    this.getDateOnInit();
    this.getHeaderDetails();
    this.onResize(this.changescreenwidth);
    this.loadEtrappAlerts();
    this.loadCartridgeAlerts();
    // this.initSmallCharts();
    this.getChannel();
    this.newduallinechart();
    this.performancebarchart();

    this.paymentForm = this.fb.group({
      email: ["", { disabled: true }, [Validators.required, Validators.email]],
      amount: [0, { disabled: true }, [Validators.required]],
      cardHolderName: ["", [Validators.required]],
    });

    if (this.resellerService.subsVar === undefined) {
      this.resellerService.subsVar =
        this.resellerService.invokeFirstComponentFunction.subscribe(
          (name: any) => { }
        );
    }
    this.screenwidth = window.innerWidth;
    this.screenheight = window.innerHeight;
  }



  daycheck() {
    const d = new Date();
    let day = d.getDay();
    setTimeout(() => {
      this.createChartGauge();
      this.createChartGauge2();
      this.createChartGauge3();
    }, 200);
  }

  newFunction(name: any) {
    this.openFormModal(this.addResellerModal);
    this.dashboardVar.resellers.find((reseller: any) => {
      if (name == reseller.name) {
        this.addResellerForm.controls["resellerId"].setValue(reseller.id);
        this.addResellerForm.controls["name"].setValue(reseller.name);
      }
    });
  }
  getst() {
    this.createChartGauge();
    this.createChartGauge2();
    this.createChartGauge3();
  }

  loadGaugeCharts() {
    this.AlarmGaugeChart = {
      canvasWidth: 250,
      needleValue: this.dashboardVar.alarmPercentage,
      centralLabel: "",
      name: "Gauge chart",
      bottomLabel: this.dashboardVar.alarmPercentage,
      options: {
        hasNeedle: true,
        needleColor: "black",
        needleUpdateSpeed: 1000,
        arcColors: ["rgb(28, 135, 117)", "rgb(247, 165, 1)", "rgb(235, 0, 0)"],
        arcDelimiters: [35, 65],
        rangeLabel: ["0", "100"],
        needleStartValue: 50,
        centralLabel: "65",
        rangeLabelFontSize: 12,
        centralLabelFontSize: 12,
      },
    };
    this.CartridgeGaugeChart = {
      canvasWidth: 250,
      needleValue: this.dashboardVar.cartHealth,
      centralLabel: "",
      name: "Gauge chart",
      bottomLabel: this.dashboardVar.cartHealth,
      options: {
        hasNeedle: true,
        needleColor: "black",
        needleUpdateSpeed: 1000,
        arcColors: ["rgb(235, 0, 0)", "rgb(247, 165, 1)", "rgb(28, 135, 117)"],
        arcDelimiters: [35, 65],
        rangeLabel: ["0", "100"],
        needleStartValue: 50,
        rangeLabelFontSize: 12,
        centralLabelFontSize: 12,
      },
    };
    this.eTrappGaugeChart = {
      canvasWidth: 250,
      needleValue: this.dashboardVar.etrappHealth,
      centralLabel: "",
      name: "Gauge chart",
      bottomLabel: this.dashboardVar.etrappHealth,
      options: {
        hasNeedle: true,
        needleColor: "black",
        needleUpdateSpeed: 1000,
        arcColors: ["rgb(235, 0, 0)", "rgb(247, 165, 1)", "rgb(28, 135, 117)"],
        arcDelimiters: [35, 65],
        rangeLabel: ["0", "100"],
        needleStartValue: 50,
        rangeLabelFontSize: 12,
        centralLabelFontSize: 12,
      },
    };
  }

  LoadMap() {
    var mapProp = {
      center: new google.maps.LatLng(this.avglat, this.avglng),
      zoom: 6,
      panControl: true,
      zoomControl: true,
      scaleControl: false,
      draggable: true,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      travelMode: google.maps["DirectionsTravelMode"].DRIVING,
      options: {
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  sumLatLng(arr) {
    if (arr.length !== 0) {
      arr.forEach((element) => {
        let obj = {
          lat: parseFloat(element.gpsLocation.split(",")[0]),
          lng: parseFloat(element.gpsLocation.split(",")[1]),
        };
        this.latitude.push(obj.lat);
        this.longitude.push(obj.lng);
      });
      var sum = 0;
      this.latitude.map((lat) => (sum += lat));
      this.avglat = sum / this.latitude.length;
      var sum1 = 0;
      this.longitude.map((lng) => (sum1 += lng));
      this.avglng = sum1 / this.longitude.length;
      this.LoadMap();
    }
  }

  checkReseller() {
    this.dashboardVar.resellerId = this.global.getProObj().resellerId;
    if (this.dashboardVar.resellerId === null) {
      this.openFormModal(this.addResellerModal);
    } else {
      this.global.name = this.global.getProObj().resellerName;
    }
  }

  openFormModal(resellerModal: TemplateRef<any>) {
    this.global.modalConfig.class = "modal-md modal-dialog-centered";
    this.resellerModalRef = this.modalService.show(
      resellerModal,
      this.global.modalConfig
    );
  }

  openInstructionsModal(instructionsModal: TemplateRef<any>) {
    this.global.modalConfig.class = "modal-lg modal-dialog-centered";
    this.instructionsModalRef = this.modalService.show(
      instructionsModal,
      this.global.modalConfig
    );
  }

  initSmallCharts() {

    this.smallChartData = {
      labels: [],
      datasets: [
        {
          label: "",
          borderColor: Colors.getColors().themeColor4,
          pointBorderColor: Colors.getColors().themeColor1,
          pointHoverBackgroundColor: Colors.getColors().themeColor1,
          pointHoverBorderColor: Colors.getColors().themeColor1,
          pointRadius: 3,
          pointBackgroundColor: Colors.getColors().themeColor1,
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          fill: false,
          borderWidth: 2,
          data: this.etrappAlarmsChart,
          datalabels: {
            align: "end",
            anchor: "end",
          },
        },
      ],
    };
    this.smallChartData1 = {
      labels: [],
      datasets: [
        {
          label: "",
          borderColor: Colors.getColors().themeColor4,
          pointBorderColor: Colors.getColors().themeColor1,
          pointHoverBackgroundColor: Colors.getColors().themeColor1,
          pointHoverBorderColor: Colors.getColors().themeColor1,
          pointRadius: 3,
          pointBackgroundColor: Colors.getColors().themeColor1,
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          fill: false,
          borderWidth: 2,
          data: this.cartridgesChart,
          datalabels: {
            align: "end",
            anchor: "end",
          },
        },
      ],
    };
    this.smallChartData2 = {
      labels: [],
      datasets: [
        {
          label: "",
          borderColor: Colors.getColors().themeColor4,
          pointBorderColor: Colors.getColors().themeColor1,
          pointHoverBackgroundColor: Colors.getColors().themeColor1,
          pointHoverBorderColor: Colors.getColors().themeColor1,
          pointRadius: 3,
          pointBackgroundColor: Colors.getColors().themeColor1,
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          fill: false,
          borderWidth: 2,
          data: this.customerServiceChart,
          datalabels: {
            align: "end",
            anchor: "end",
          },
        },
      ],
    };
    this.smallChartData3 = {
      labels: [],
      datasets: [
        {
          label: "",
          borderColor: Colors.getColors().themeColor4,
          pointBorderColor: Colors.getColors().themeColor1,
          pointHoverBackgroundColor: Colors.getColors().themeColor1,
          pointHoverBorderColor: Colors.getColors().themeColor1,
          pointRadius: 3,
          pointBackgroundColor: Colors.getColors().themeColor1,
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          fill: false,
          borderWidth: 2,
          data: this.ordersChart,
          datalabels: {
            align: "end",
            anchor: "end",
          },
        },
      ],
    };
    this.smallChartData4 = {
      labels: [],
      datasets: [
        {
          label: "",
          borderColor: Colors.getColors().themeColor4,
          pointBorderColor: Colors.getColors().themeColor1,
          pointHoverBackgroundColor: Colors.getColors().themeColor1,
          pointHoverBorderColor: Colors.getColors().themeColor1,
          pointRadius: 3,
          pointBackgroundColor: Colors.getColors().themeColor1,
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          fill: false,
          borderWidth: 2,
          data: this.rewardsChart,
          datalabels: {
            align: "end",
            anchor: "end",
          },
        },
      ],
    };
  }

  getDateOnInit() {
    let currentDate = new Date();
    let previousWeekDate = new Date(
      currentDate.setDate(currentDate.getDate() - 7)
    );
    this.dashboardVar.previousEtrappDateOnInit = previousWeekDate;
    this.dashboardVar.previousChartsdataDateOnInit = previousWeekDate;
    this.dashboardVar.previousCartDateOnInit = this.previousDate(
      this.global.localDateToUTC(new Date()),
      720
    );
  }

  onChangeOrderBy(item, device: number) {
    let datelength = new Date().toISOString().length;
    let date = new Date().toISOString().substring(0, datelength);
    this.getTimeDifference(date, item.value, device);
    if (device == 1) {
      this.dashboardVar.eTrappSelectedVlaue = item;
      this.loadEtrappAlerts();
    } else if (device == 2) {
      this.dashboardVar.cartSelectedVlaue = item;
      this.loadCartridgeAlerts();
    }
    else if (device == 3) {
      this.dashboardVar.chartsSelectedValue = item;
      this.loadChartsdata();
    }
  }

  getTimeDifference(date: any, obj: any, dev: number) {
    let date1: Date = date;
    switch (obj) {
      case "4hours":
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 4);
        } else if (dev == 2) {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 4);
        } else {
          this.dashboardVar.previousChartsdataDate = this.previousDate(date1, 4);
        }
        break;
      case "8hours":
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 8);
        } else if (dev == 2) {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 8);
        } else {
          this.dashboardVar.previousChartsdataDate = this.previousDate(date1, 8);
        }
        break;
      case "1day":
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 24);
        } else if (dev == 2) {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 24);
        } else {
          this.dashboardVar.previousChartsdataDate = this.previousDate(date1, 24);
        }
        break;
      case "7days":
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 168);
        } else if (dev == 2) {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 168);
        } else {
          this.dashboardVar.previousChartsdataDate = this.previousDate(date1, 168);
        }
        break;
      case "30days":
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 720);
        } else if (dev == 2) {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 720);
        } else {
          this.dashboardVar.previousChartsdataDate = this.previousDate(date1, 720);
        }
        break;
    }
  }

  previousDate(date: any, hours: number) {
    ;
    date = new Date(date);
    let numberOfMiliseconds = date.getTime();
    let addMlSeconds = hours * 60 * 60 * 1000;
    let newDate = new Date(numberOfMiliseconds - addMlSeconds);
    return newDate;
  }

  calmaxandmin(arr) {
    let mn = new maxmin();
    let sum = 0;

    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    mn.max = Math.max(...arr);
    mn.min = Math.min(...arr);
    mn.Avrg = sum / arr.length;
    return mn;
  }

  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  fullScreenClickWidget(el: HTMLElement, isMap?) {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      el.requestFullscreen();
      if (isMap !== undefined) {
        ;

        this.dashboardVar.alertLvlObj = {
          isHigh: this.dashboardVar.alertLvlObj.isHigh,
          isMedium: this.dashboardVar.alertLvlObj.isMedium,
          isLow: this.dashboardVar.alertLvlObj.isLow,
        };
      }
    } else {
      if (isMap !== undefined) {
        this.dashboardVar.alertLvlObj = {
          isHigh: this.dashboardVar.alertLvlObj.isHigh,
          isMedium: this.dashboardVar.alertLvlObj.isMedium,
          isLow: this.dashboardVar.alertLvlObj.isLow,
        };
      }
      this.dashboardVar.markers.forEach((obj) => {
        obj.setVisible(true);
      });
      document.exitFullscreen();
    }
  }

  getChannel() {
    this.services.getRequest(`PubSub/SubscribePubSub`).subscribe(
      (response) => {
        if (!response.Error) {
          let url = response.data;
          this.pubSubChannel = webSocket(url);
          this.pubSubChannel.subscribe(
            async (msg: any) => this.refreshDashboard(msg),
            (err) => console.log(err)
          );
        } else if (response.Error) {
        }
      },
      (error) => { }
    );
  }

  refreshDashboard(msg) {
    if (this.global.getProObj().id === msg.ProId) {
      this.loadMapdata();
      this.loadEtrappAlerts();
      this.loadCartridgeAlerts();
      this.getHeaderDetails();
    } else {
    }
  }

  loadMapdata() {
    this.global.showLoader("#mapsDiv");
    this.services
      .getRequest(
        `Dashboard/GetEtrappAlertsByProId?proId=${this.global.getProObj().id}`
      )
      .subscribe(
        (response) => {

          if (response.statusCode === "200") {
            // response.data.alertsData.data.forEach((element, index, self) => {
            //   let isDuplicate = this.isDuplication(
            //     this.dashboardVar.mapdata,
            //     element.id
            //   );
            //   if (!isDuplicate) {
            //     element.count = 1;
            //     this.dashboardVar.mapdata.push(element);
            //   } else {
            //     let mapDataIndex = this.dashboardVar.mapdata.findIndex(
            //       (it) => it.id == element.id
            //     );
            //     this.dashboardVar.mapdata[mapDataIndex].count += 1;
            //   }
            // });
            debugger
            this.dashboardVar.mapdata = response.data.alertsData.data
            this.drawMarker(this.dashboardVar.mapdata);
            this.global.HideLoader("#mapsDiv");
          } else if (response.statusCode == "409") {
            this.global.HideLoader("#mapsDiv");
            this.global.showNotification("Info", "", "No eTrapp Alerts found in map");
          } else {
            this.global.HideLoader("#mapsDiv");
            this.global.showNotification("Error", "", "Something went wrong.");
          }
        },
        (error) => {
          this.global.HideLoader("#mapsDiv");
        }
      );
  }

  isDuplication(arr, id) {
    let index = arr.findIndex((it) => it.id === id);
    return index > -1 ? true : false;
  }

  infowindow = new google.maps.InfoWindow();
  drawMarker(arr) {
    

    this.sumLatLng(arr);

    let groupedAlerts = {};
    arr.forEach((element) => {
      if (!groupedAlerts[element.alertSeverityLevel]) {
        groupedAlerts[element.alertSeverityLevel] = [];
      }
      groupedAlerts[element.alertSeverityLevel].push(element.alertDefinition);
    });

    this.dashboardVar.markers = [];
    let marker;
    arr.forEach((element) => {
      let obj = {
        count: element.count,
        lat: parseFloat(element.gpsLocation.split(",")[0]),
        lng: parseFloat(element.gpsLocation.split(",")[1]),
      };
      let clr =
        element.alertSeverityLevel === 1
          ? "red"
          : element.alertSeverityLevel === 2
            ? "yellow"
            : element.alertSeverityLevel === 3
              ? "green"
              : "";
      marker = this.global.drawMarker(obj, this.map, clr, "etrapp");
      google.maps.event.addListener(
        marker,
        "click",
        ((marker, i) => {
          if (this.infowindow != null) {
            this.infowindow.close();
          }
          return () => {
            const combinedAlerts = groupedAlerts[element.alertSeverityLevel].join(", ");
            const contentString = `<div class="card"><div class="card-body py-1"><div class="text-center">
          <p class="list-item-heading mb-1">${element.consumerName}</p>
          <p class="mb-2 text-muted text-small">${combinedAlerts}</p>
          <p class="mb-2 text-muted text-small">${element.deviceName}</p>
          <p class="mb-2 text-muted text-small"><span>Installed By: </span>${element.installedBy
              }</p>
          <p class="mb-2 text-muted text-small"><span>Installed On: </span>${new Date(
                element.installedOn
              ).toLocaleDateString()}</p>
          </div></div></div>`;
            debugger

            this.infowindow.setContent(contentString);
            this.infowindow.open(this.map, marker);
          };
        })(marker)
      );
      let infoArray = [];
      infoArray.push(this.infowindow);
      marker.alertSeverityLevel =
        element.alertSeverityLevel === 1
          ? "High"
          : element.alertSeverityLevel === 2
            ? "Medium"
            : element.alertSeverityLevel === 3
              ? "Low"
              : "";
      this.dashboardVar.markers.push(marker);
      let map = this.map;
      google.maps.event.addListener(map, "click", function (event) {
        for (var i = 0; i < infoArray.length; i++) {
          infoArray[i].close();
        }
      });
      const renderer = {
        render: ({ count, position }) =>
          new google.maps.Marker({
            label: { text: String(count), color: "black", fontSize: "10px" },
            position,
            icon: this.getIcon(),
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          }),
      };
      let markers = this.dashboardVar.markers;
      let markerCluster = new markerClusterer.MarkerClusterer({
        map,
        markers,
        renderer,
      });
      let _this = this;
      google.maps.event.addListener(markerCluster, "click", function (cluster) {
        if (this.infowindow != null || this.infowindow !== 0) {
          _this.infowindow.open();
          markerCluster.map.zoom = 7;
        }
        _this.infowindow == null;
        _this.infowindow.close();
      });
    });
  }

  getIcon() {
    const icon = {
      url:
        this.dashboardVar.markers.filter(
          (obj) => obj.alertSeverityLevel === "High" && obj.visible
        ).length >= 1
          ? "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png"
          : this.dashboardVar.markers.filter(
            (obj) => obj.alertSeverityLevel === "Medium" && obj.visible
          ).length >= 1
            ? "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png"
            : this.dashboardVar.markers.filter(
              (obj) => obj.alertSeverityLevel === "Low" && obj.visible
            ).length >= 1
              ? "../../../assets/img/darkGreenClusterMap.png"
              : "",
    };
    return icon;
  }

  onSelectLvl(type: any, bool) {

    this.dashboardVar.markers.forEach((obj) => {
      if (obj.alertSeverityLevel === type) {
        obj.setVisible(!bool);
      }
    });
  }

  loadEtrappAlerts() {
    ;
    const obj = {
      id: this.global.getProObj().id,
      startFrom: 0,
      endTo: 0,
      startDate:
        this.dashboardVar.previousEtrappDate == undefined
          ? this.dashboardVar.previousEtrappDateOnInit
          : this.dashboardVar.previousEtrappDate,
      endDate: new Date().toISOString(),
    };
    this.global.showLoader("#eTrappAlerts");
    this.dashboardVar.loadingEtrappAlert = "Loading etrapp alerts data...";
    this.services
      .postRequest(`Dashboard/GetDashboardEtrappAlertsByProId`, obj)
      .subscribe(
        (response) => {
          this.dashboardVar.eTrappAlertsArr = [];
          if (response.statusCode == "200") {

            response.data.map((element: any) => {
              var dashObj = {
                customer: {
                  name: element.firstname + " " + element.lastname,
                  phoneNumber: element.phoneNumber,
                  address: element.address1,
                  email: element.email,
                  state: element.stateCode,
                  city: element.cityName,
                  zip: element.zipCode,
                  createdOn: element.createdOn
                },
                completeAddress: {
                  city: element.cityName,
                  country: element.countryCode,
                  zip: element.zipCode,
                  state: element.stateCode,
                },
                deviceName: element.deviceName,
                generatedOn: element.generatedOn,
                definition: element.definition,
                id: element.id,
                etrappUnitId: element.etrappUnitId,
                etrappAddress: element.etrappAddress,
                isAcknowledged: element.isAcknowledged
              };
              this.dashboardVar.eTrappAlertsArr.push(dashObj);
            });
            this.global.HideLoader("#eTrappAlerts");
          } else if (response.statusCode == "409") {
            this.global.HideLoader("#eTrappAlerts");
            this.dashboardVar.loadingEtrappAlert = "No Record Found";
          } else {
            this.global.HideLoader("#eTrappAlerts");
          }
        },
        (error) => {
          this.global.HideLoader("#eTrappAlerts");
        }
      );
  }
  formatDate(date: Date): string {
    return this.datePipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", 'UTC');
  }

  loadChartsdata() {

    let datetosend: any;
    this.dashboardVar.previousChartsdataDate == undefined ?
      datetosend = this.dashboardVar.previousChartsdataDateOnInit : datetosend = this.dashboardVar.previousChartsdataDate,
      datetosend = this.formatDate(datetosend)
    if (!this.selectedmacid) {
      this.global.showNotification("info", "", "Please select etrapp first.");
      this.dashboardVar.chartsSelectedValue = this.itemOptionsOrders[0];
    }
    else {
      this.global.showLoader("#stockchart");
      this.global.showLoader("#acrunning");
      this.global.showLoader("#airfilters");
      this.services
        .getRequest(`Dashboard/GetCosmosDataByEtrappUnitIdAndDate?macId=${this.selectedmacid}&dateFrom=${datetosend}`)
        .subscribe(
          (response) => {
            if (response.statusCode == 200) {

              let obj1 = response.data.rh;
              let obj2 = response.data.tempF
              let obj3 = response.data.bmp
              let obj4 = response.data.acRunning
              obj4.data = obj4.data.map(subarray => [subarray[0], subarray[1] * 70]);
              let obj5 = response.data.watPct
              obj4.step = true

              const series = [{ ...obj2 }, { ...obj1 }, { ...obj3 }]
              const acrunning = [{ ...obj4 }, { ...obj5 }]
              this.createChart(series, acrunning);

            }

          },
          (error) => {
            this.global.HideLoader("#cartAlerts");
          }
        );
    }
  }

  loadCartridgeAlerts() {
    const obj = {
      id: this.global.getProObj().id,
      startFrom: 0,
      endTo: 0,
      endDate: new Date().toISOString(),
      startDate:
        this.dashboardVar.previousCartDate == undefined
          ? this.dashboardVar.previousCartDateOnInit
          : this.dashboardVar.previousCartDate,
    };
    this.global.showLoader("#cartAlerts");
    this.dashboardVar.loadingCartAlert = "Loading cartridge alert data...";
    this.services
      .postRequest(`Dashboard/GetDashboardCartridgeAlertsByProId`, obj)
      .subscribe(
        (response) => {
          this.dashboardVar.cartridgeAlertsArr = [];
          if (response.statusCode == "200") {
            response.data.map((element: any) => {
              var dashObj = {
                customer: {
                  name: element.firstname + " " + element.lastname,
                  phoneNumber: element.phoneNumber,
                  address: element.address1,
                  email: element.email,
                  state: element.stateCode,
                  city: element.cityName,
                  zip: element.zipCode,
                },
                completeAddress: {
                  city: element.cityName,
                  country: element.countryCode,
                  zip: element.zipCode,
                  state: element.stateCode,
                },
                deviceName: element.deviceName,
                generatedOn: element.generatedOn,
                definition: element.definition,
                id: element.id,
              };
              this.dashboardVar.cartridgeAlertsArr.push(dashObj);
            });
            this.global.HideLoader("#cartAlerts");
          } else if (response.statusCode == "409") {
            this.global.HideLoader("#cartAlerts");
            this.dashboardVar.loadingCartAlert = "No record found";
          } else {
            this.global.HideLoader("#cartAlerts");
          }
        },
        (error) => {
          this.global.HideLoader("#cartAlerts");
        }
      );
  }

  openConsumerModal(obj: any) {
    this.getModalDetails(obj);
  }

  getModalDetails(obj: any) {

    this.global._show_loader = true;
    this.services
      .getRequest(`Consumer/GetConsumerDetailsById?Id=${obj.id}`)
      .subscribe(
        (response) => {
          if ((response.statusCode = 200)) {
            this.global._show_loader = false;
            this.dashboardVar.modalDetailsComplete = { ...response, ...obj };
            this.notifyAlertModal.openModal2(
              this.dashboardVar.modalDetailsComplete
            );

          } else {
            ("Something went wrong while fetching consumer data.");
            this.global.showNotification("Error", "", "Record already exist");
            this.global._show_loader = false;
          }
        },
        (error) => {
          this.global.showNotification("Error", "", "Record already exist");
          this.global._show_loader = false;

        }
      );
  }

  getHeaderDetails() {
    this.services
      .getRequest(
        `Consumer/GetCustomerSummaryByProId?proId=${this.global.getProObj().id}`
      )
      .subscribe(
        (response) => {
          if (response.statusCode == "200") {
            this.dashboardVar.totalCustomers = response.data.totalCount;
            this.dashboardVar.daySinceNewCustomer = response.data.daysSince;
          } else if (response.statusCode == "409") {
            this.dashboardVar.totalCustomers = 0;
            this.dashboardVar.daySinceNewCustomer = 0;
          } else {
          }
        },
        (error) => { }
      );
  }

  getSmallChartData(flag: any) {
    this.services
      .getRequest(
        `Dashboard/GetDashboardStats?ProId=${this.global.getProObj().id}`
      )
      .subscribe(
        (response) => {
          if ((response.statusCode = 200)) {
            this.dashboardVar.etrappHealth = Math.round(
              response.dashboardGuages.averageEtrappHealth
            );
            this.dashboardVar.cartHealth = Math.round(
              response.dashboardGuages.averageCartridgeHealth
            );
            this.dashboardVar.alarmPercentage = Math.round(
              response.dashboardGuages.alarmPercentage
            );
            this.loadGaugeCharts();
            this.daycheck();

            if (flag == "widgets") {
              for (var item in response.dashboardWidgets) {
                this.makeChartData(item, response.dashboardWidgets);
              }
              setTimeout(() => {
                $(".chartjs-render-monitor").each(function () {
                  $(this).click();
                });
              }, 100);
            }
          } else {
            ("Something went wrong while fetching consumer data.");
          }
        },
        (error) => { }
      );
  }

  makeChartData(item, obj) {
    var weekDays = [
      { day: "Sunday", count: 0 },
      { day: "Monday", count: 0 },
      { day: "Tuesday", count: 0 },
      { day: "Wednesday", count: 0 },
      { day: "Thursday", count: 0 },
      { day: "Friday", count: 0 },
      { day: "Saturday", count: 0 },
    ];
    var newArr = [];
    var newarr2 = [];
    switch (item) {
      case "alertData":
        if (obj.alertData.length <= 7) {
          if (obj.alertData.length !== 0) {
            const d = new Date();
            let day = d.getDay();
            let days = weekDays[d.getDay()];
            let remDays = weekDays.splice(0, day + 1);
            let newArr = remDays.reverse().concat(weekDays.reverse());
            newarr2 = newArr.reverse();
          }
        }
        obj.alertData.map((custData: any) => {
          newarr2.some((weekDay: any) => {
            if (custData.day == weekDay.day) {
              weekDay.count = custData.count;
              return;
            }
          });
        });
        newarr2.some((it) => {
          this.smallChartData.labels.push(it.day);
          this.etrappAlarmsChart.push(it.count);
        });
        if (obj.alertData.length == 0) {
          this.schartHEtrapp = 0;
          this.schartLEtrapp = 0;
          this.schartAEtrapp = 0;
        } else {
          console.log(this.eTrappsAlarm, 'alarm chart')
          this.eTrappsAlarm.chart.update();
          var a = this.calmaxandmin(this.etrappAlarmsChart);
          this.schartLEtrapp = a.min;
          this.schartHEtrapp = a.max;
          this.schartAEtrapp = a.Avrg;
          this.schartAEtrapp = (
            Math.round(this.schartAEtrapp * 100) / 100
          ).toFixed(2);
        }
        break;
      case "cartridgeData":
        if (obj.cartridgeData.length <= 7) {
          if (obj.cartridgeData.length !== 0) {
            const d = new Date();
            let day = d.getDay();
            let days = weekDays[d.getDay()];
            let remDays = weekDays.splice(0, day + 1);
            let newArr = remDays.reverse().concat(weekDays.reverse());
            newarr2 = newArr.reverse();
          }
        }
        obj.cartridgeData.map((custData: any) => {
          newarr2.some((weekDay: any) => {
            if (custData.day == weekDay.day) {
              weekDay.count = custData.count;
              return;
            }
          });
        });
        newarr2.some((it) => {
          this.smallChartData1.labels.push(it.day);
          this.cartridgesChart.push(it.count);
        });
        if (obj.cartridgeData.length == 0) {
          this.schartHCart = 0;
          this.schartLCart = 0;
          this.schartACart = 0;
        } else {
          this.cartridges.chart.update();
          var a = this.calmaxandmin(this.cartridgesChart);
          this.schartLCart = a.min;
          this.schartHCart = a.max;
          this.schartACart = a.Avrg;
          this.schartACart = (Math.round(this.schartACart * 100) / 100).toFixed(
            2
          );
          weekDays.map((it) => (it.count = 0));
        }
        break;

      case "customerServiceData":
        if (obj.customerServiceData.length <= 7) {
          if (obj.customerServiceData.length !== 0) {
            const d = new Date();
            let day = d.getDay();
            let remDays = weekDays.splice(0, day + 1);
            newArr = remDays.reverse().concat(weekDays.reverse());
            newarr2 = newArr.reverse();
          }
        }
        obj.customerServiceData.map((custData: any) => {
          newarr2.some((weekDay: any) => {
            if (custData.day == weekDay.day) {
              weekDay.count = custData.count;
              return;
            }
          });
        });
        newArr.some((it) => {
          this.smallChartData2.labels.push(it.day);
          this.customerServiceChart.push(it.count);
        });
        if (obj.customerServiceData.length == 0) {
          this.schartHCustomer = 0;
          this.schartLCustomer = 0;
          this.schartACustomer = 0;
        } else {
          this.customerService.chart.update();
          var a = this.calmaxandmin(this.customerServiceChart);
          this.schartLCustomer = a.min;
          this.schartHCustomer = a.max;
          this.schartACustomer = a.Avrg;
          this.schartACustomer = (
            Math.round(this.schartACustomer * 100) / 100
          ).toFixed(2);
        }
        break;

      case "orderData":
        if (obj.orderData.length <= 7) {
          if (obj.orderData.length !== 0) {
            const d = new Date();
            let day = d.getDay();
            let days = weekDays[d.getDay()];
            let remDays = weekDays.splice(0, day + 1);
            let newArr = remDays.reverse().concat(weekDays.reverse());
            newarr2 = newArr.reverse();
          }
        }
        obj.orderData.map((custData: any) => {
          newarr2.some((weekDay: any) => {
            if (custData.day == weekDay.day) {
              weekDay.count = custData.count;
              return;
            }
          });
        });
        newarr2.map((it: any) => {
          this.smallChartData3.labels.push(it.day);
          this.ordersChart.push(it.count);
        });
        if (obj.orderData.length == 0) {
          this.schartLOrder = 0;
          this.schartHOrder = 0;
          this.schartAOrder = 0;
        } else {
          this.orderChart.chart.update();
          var a = this.calmaxandmin(this.ordersChart);
          this.schartLOrder = a.min;
          this.schartHOrder = a.max;
          this.schartAOrder = a.Avrg;
          this.schartAOrder = (
            Math.round(this.schartAOrder * 100) / 100
          ).toFixed(2);
        }
        break;
      case "rewardsData":
        if (obj.rewardsData.length <= 7) {
          if (obj.rewardsData.length !== 0) {
            const d = new Date();
            let day = d.getDay();
            let days = weekDays[d.getDay()];
            let remDays = weekDays.splice(0, day + 1);
            let newArr = remDays.reverse().concat(weekDays.reverse());
            newarr2 = newArr.reverse();
          }
        }
        obj.rewardsData.map((custData: any) => {
          newarr2.some((weekDay: any) => {
            if (custData.day == weekDay.day) {
              weekDay.count = custData.count;
              return;
            }
          });
        });
        newarr2.map((it: any) => {
          this.smallChartData4.labels.push(it.day);
          this.rewardsChart.push(it.count);
        });
        if (obj.rewardsData.length == 0) {
          this.schartHReward = 0;
          this.schartLReward = 0;
          this.schartAReward = 0;
        } else {
          this.RewardsPoints.chart.update();
          var a = this.calmaxandmin(this.rewardsChart);
          this.schartLReward = a.min;
          this.schartHReward = a.max;
          this.schartAReward = a.Avrg;
          this.schartAReward = (
            Math.round(this.schartAReward * 100) / 100
          ).toFixed(2);
          weekDays.map((it) => (it.count = 0));
        }
        break;
    }
  }

  closeAddResellerModal() {
    this.resellerModalRef.hide();
    
    
  }

  submitAddReseller(obj: any) {
    this.saveReseller(obj);
  }

  getResellers() {
    this.services.getRequest(`Reseller/GetAllResellers`).subscribe(
      (response) => {
        if (response.statusCode == "200") {
          this.dashboardVar.resellers = response.data;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Records do not exist");
        this.global._show_loader = false;
      }
    );
  }

  initResellerForm() {
    this.addResellerForm = this.formBuilder.group({
      id: [
        this.global.getProObj().id,
        Validators.compose([Validators.nullValidator]),
      ],
      resellerId: [0, Validators.compose([Validators.nullValidator])],
      name: [null, Validators.compose([Validators.required])],
    });
  }

  saveReseller(obj) {
    let payload = this.global.getProObj();
    this.dashboardVar.resellers.find((reseller: any) => {
      if (obj.name == reseller.name) {
        payload.resellerId = reseller.id;
      }
    });
    this.global._show_loader = true;
    this.services.postRequest("Pro/UpdatePro", payload).subscribe(
      (response) => {
        if (response.statusCode == "410") {
          this.global._show_loader = false;
        } else if (response.statusCode == "201") {
          this.closeAddResellerModal();
          this.global.showNotification(
            "Success",
            "",
            "Reseller saved successfully."
          );
          this.global.name = this.addResellerForm.value.name;
          localStorage.setItem("RESELLER", this.global.name);
          let newObj = this.global.getProObj();
          newObj.resellerId = this.addResellerForm.value.resellerId;
          newObj.resellerName = this.addResellerForm.value.name;
          localStorage.setItem(
            this.global.settings.PRO_OBJ,
            btoa(JSON.stringify(newObj))
          );
          this.global._show_loader = false;
          this.openInstructionsModal(this.instructionsModal);
        } else {
          this.global.showNotification("Error", "", "Something went wrong.");
          this.global._show_loader = false;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Something went wrong.");

        this.global._show_loader = false;
      }
    );
  }
}

export class maxmin {
  max: number = 0;
  min: number = 0;
  Avrg: number = 0;
}

class Note {
  date: Date = new Date();
  url: any;
  name: string = "";
  suppress: boolean = false;
  collectorname: string = "";
  pdf_name: any;
  NOTE_ID: number = 0;
  note: string = "";
  priority: string = "";
  AGENCY_ID: string = "";
  aliasName: string = "";
  inserted_At: any = "";
  empID: any;
  debtor = {
    name: "",
    debtor_ID: 0,
  };
}
